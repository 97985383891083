export const useEventsStore = defineStore('events', {
  state: () => {
    return {
      total: 0,
      currentPage: 1,
      perPage: 50,
      searchQuery: '',
      events: [],
    };
  },

  actions: {
    async fetchEvents({ filter, endDateLimit } = {}) {
      const response = await useApi.get('/events', {
        params: {
          filter,
          endDateLimit,
        },
      }).catch((error) => {
        console.error(error);
        useToast.error(useT('bcd114'));
      });

      if (response) {
        this.total = response.total;
        this.events = response.results;
      }
    },

    async changePlan({ eventId, planId, workspaceDomain }) {
      const response = await useApi.post(`/events/${eventId}/change-plan`, {
        body: {
          planId,
          workspaceDomain,
        },
      }).catch((error) => {
        console.error(error);
        useToast.error(useT('bcd114'));
      });

      return response;
    },
  },

  getters: {
    getEvents(state) {
      const eventsFiltered = filterSearchInFields({
        items: state.events,
        fields: ['general.title', 'workspace.domain', 'workspace.name'],
        query: state.searchQuery,
      });

      return {
        total: eventsFiltered.length,
        results: eventsFiltered.slice(state.perPage * (state.currentPage - 1), state.perPage * state.currentPage),
      };
    },
  },
});
